import {Injectable} from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {EnvService} from '../utils/env.service';
import {constants} from '../../../config/app.constants';
import { throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe : 'response'
};

@Injectable({
    providedIn: 'root'
})

export class PaymentHistoryService {
  private paymentCms: any;
  private paymentMade: any;

  constructor(private http: HttpClient, private env: EnvService) { }

  public getPaymentsMade(
    codPro: number,
    numeroDias: number,
    filtroFechas: number,
    fechaDesde: string,
    fechaHasta: string,
    sucursal: any) {
      const urlCmsPayment = `${this.env.apiGatewayFront}${constants.config.getPaymentHistory}` +
      `?codigoProveedor=${codPro}&numeroDias=${numeroDias}&filtroFechas=${filtroFechas}` +
      `&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&sucursal=${sucursal ? sucursal.code : ''}`;
      this.paymentMade = this.http.get(urlCmsPayment);
      return this.paymentMade;
    }


    // Obtener informacion en historico de pago.
    public getPaymentItems(data: any) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/form-data');
      const body: FormData = new FormData();
      body.append('formData', JSON.stringify(data));
      const url = this.env.apiGatewayFront + constants.config.getPaymentHistory;
      return this.http.post<any>(url, body, {headers})
        .pipe(
            tap(_ => console.log('fetched paymentItems')),
            catchError(this.handleError)
        );
    }

    // Obtener informacion en detalle de historico de pago.
    public getRelatedPaymentsById(id: string, tipoDoc: any, docCliente: any, dias: any) {
      const json = { nro_docto: id, id_tipo_docto: tipoDoc, Id_Cliente: docCliente, dia: dias};
      const url = this.env.apiGatewayFront + constants.config.getRelatedPaymentsForHistoryDetail;
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/form-data');
      const body: FormData = new FormData();
      body.append('formData', JSON.stringify(json));

      return this.http.post<any>(url, body, {headers})
        .pipe(
            tap(_ => console.log('fetched paymentRelatedItems')),
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
          console.log('an error ocurred', error.error.message);
      } else {
          console.log('Backend error', error.status);
          console.log('client side error', error.error);
      }
      return throwError(
          'Smething wrong' + error.error
      );
    }


    public getRetornarDias() {
      const url = this.env.apiGatewayFront;
      return this.http.get(`${url}/${constants.config.getRetornarDiasErpBackend}`).toPromise();
    }
  }

