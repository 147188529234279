import { Component, OnInit, VERSION } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl,ValidationErrors, AbstractControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PasswordService } from '../services/login/password.service';

@Component({
  selector: 'app-login',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  registerForm: FormGroup;
  contentCmsIntro: any;
  contentCmsTips: any;
  logo: string;

  public version = VERSION.full;
  public loadingChangePass = false;
  public messageRegex = "";
  public showMessageRegex = false;
  public reactiveForm: FormGroup = new FormGroup({
    recaptchaReactive: new FormControl(null, Validators.required)
  });

  constructor(private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService,
    private passwordService: PasswordService) { }


  ngOnInit() {
    this.getCmsIntro();
    this.getCmsTips();
    this.logo = localStorage.getItem('logo');
  }

  recoveryPassword(form: NgForm) {
    this.checkPassword(form);
    let userEmail = form.value.inputEmail;
    let currentPassword = form.value.inputCurrentPassword;
    let password = form.value.inputPassword;
    let passwordConfirmation = form.value.inputPasswordConfirmation;
    let capcha = form.value.capcha;
    this.loadingChangePass = true;
    this.registerForm = this.formBuilder.group({
      email: [userEmail, [Validators.required, Validators.email]],
      currentPassword: [currentPassword, [Validators.required]],
      password: [password, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$')]],
      passwordConfirmation: [passwordConfirmation, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$')]],
      capcha: [capcha, [Validators.required]]
    });

    if (this.registerForm.invalid) {
      if (this.registerForm.controls.email.errors) {
        if (this.registerForm.controls.email.errors.email) {
          this.showError('Lo sentimos! El correo tiene un formato incorrecto');
          return;
        }
        if (this.registerForm.controls.email.errors.required) {
          this.showError('Lo sentimos! Debe ingresar un correo válido');
          return;
        }
      }
      if (this.registerForm.controls.currentPassword.errors) {
        if (this.registerForm.controls.capcha.errors.required) {
          this.showError('Debe ingresar su contraseña actual');
          return;
        }
      }
      if (this.registerForm.controls.password.errors) {
        if (this.registerForm.controls.capcha.errors.required) {
          this.showError(this.messageRegex.toString().length > 0 ? this.messageRegex:'Lo sentimos! Debe ingresar una contraseña nueva');
          return;
        }
      }
      if (this.registerForm.controls.capcha.errors) {
        if (this.registerForm.controls.capcha.errors.required) {
          this.showError('Debe seleccionar el captcha para restablecer la contraseña');
          return;
        }
      }

    }

    this.passwordService.passwordUpdateLogin(userEmail,currentPassword,password,passwordConfirmation).subscribe((passResponse: any) => {
        if (passResponse.error) {
          this.loadingChangePass = false;
          return  this.showError(passResponse.message);
        }

        this.router.navigate(['/login']);
        this.showSucces('Tu contraseña ha sido cambiada con exito');
      });
  }

  showError(message: string) {
    this.toastr.error(message);
  }

  showSucces(message: string) {
    this.toastr.success(message);
  }

  getCmsIntro() {
    const ubication = 'RESTABLECER_PASSWORD_INTRO_AGR';
    this.passwordService.contentIntro(ubication)
      .subscribe((cmsResponseIntro: any) => {
        return this.contentCmsIntro = cmsResponseIntro;
      });
  }

  getCmsTips() {
    const ubication = 'RESTABLECER_PASSWORD_TIP_AGR';
    this.passwordService.contentTips(ubication)
      .subscribe((cmsResponseTips: any) => {
        return this.contentCmsTips = cmsResponseTips;
      });
  }

  checkPassword(form: NgForm) { // FIXME: Esto puede fallar si el id cambia
    let password = form.value.inputPassword;
    if( password.length > 0){
        var patronMayus = /[A-Z]/g;
        var patronMin = /[a-z]/g;
        var patronEspe= /[^\w]/g;
        var patroNumber = /[0-9]/g;
        let mensaje = "";
        if (!(password.length >= 12)){
            mensaje = mensaje + "\n-Más de 12 caracteres.";
        }
        if (!password.match(patronMayus)) {
            mensaje = mensaje + "\n-Presencia de caracteres mayúsculas.";
        }
        if(!password.match(patronMin)){
            mensaje = mensaje + "\n-Presencia de caracteres minúsculas.";
        }
        if(!password.match(patronEspe)){
            mensaje = mensaje + "\n-Debe contener al menos un carácter especial o símbolo.";
        }
        if(!password.match(patroNumber)){
            mensaje = mensaje + "\n-Presencia de caracteres alfanuméricos.";
        }
        if(mensaje.length > 0){
            this.showMessageRegex = true;
            this.messageRegex = "La contraseña no cumple con:" + mensaje;
        }else{
            this.showMessageRegex = false;
        }
        // console.log(this.showMessageRegex);
        // console.log(this.messageRegex);
    }
  };

  // comparePasswordValidator(control: AbstractControl): ValidationErrors | null {
  //   if (control.value !== control?.parent?.get('password')?.value) {
  //     return { compare: true };
  //   }

  //   return null;
  // }

}
