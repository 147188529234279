import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../services/utils/env.service';
import { constants } from '../../../../config/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private env: EnvService
  ) {}

  printDocument(documentName: string, documentData: string[]) {
    this.isPrinting = true;

    this.router.navigate(['print/print-child/1,2']);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      // this.router.navigate([{ outlets: { print: null }}]);
      this.router.navigate(['print-invoice']);
    });
  }

  invoiceCmsMensajePago() {
    const url =
      this.env.apiGatewayFront + `${constants.config.invoiceCmsMensajePago}`;
    const resultado = this.http.get<any>(url);
    return resultado;
  }

  getAllCms() {
    const url =
      this.env.apiGatewayFront + `${constants.config.getContentAllCms}`;
    const resultado = this.http.get<any>(url);
    return resultado;
  }
}
