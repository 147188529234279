import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header/header.service';
import { ManageLocalStorageService } from '../services/manage-localstorage-service/manage-localstorage-service';

declare var $: any;

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  b2sContent: any;

  // tslint:disable-next-line:variable-name
  constructor(
    private _hedaerService: HeaderService,
    private _manageLocalStorage : ManageLocalStorageService) { }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.getContentB2s();
  }

  getContentB2s() {
    this._hedaerService.blogEventsHeader()
      .subscribe((contentReponse: any) => {
        this._manageLocalStorage.setContentAgp(contentReponse);
        return this.b2sContent = contentReponse;
      });
  }
}
