import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../services/token/token.services';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.sass']
})
export class TokenComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private _tokenService: TokenService,
              private route: ActivatedRoute,
              private _authService: AuthService,
              private _router: Router,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.getTokenInfo();
  }


  getTokenInfo() {
    this.route.params
      .subscribe(parametros => {
        this._tokenService.getTokenService(parametros.token)
          .subscribe((tokenResponse: any) => {
            if (!tokenResponse.error) {
              const email = tokenResponse.data.email;
              const token = tokenResponse.data.token;
              if (email != null && token != null) {
                this.authentication(email, token);
              } else {
                localStorage.setItem('authenticate', 'false');
                this._router.navigate(['/login']);
                this.showError('Lo sentimos! Ocurrio un error al realizar la autenticación');
              }
            } else {
              localStorage.setItem('authenticate', 'false');
              this._router.navigate(['/login']);
              this.showError(tokenResponse.message);
            }
          }, error => {
          if (error.status === 500 || error.status === 0) {
              this._router.navigate(['/500']);
            }
          });
      });
  }

  authentication(userName: string, token: any) {
    this._authService.authenticateByToken(userName, token)
      .subscribe((authResponse: any) => {
        if (authResponse.data) {
          if (authResponse.data.isLogged) {
            if (authResponse.data.isAGP) {
              localStorage.setItem('authenticate', 'true');
              localStorage.setItem('userData', JSON.stringify(authResponse.data));
              this._router.navigate(['/payments']);
            } else {
              localStorage.setItem('authenticate', 'false');
              this._router.navigate(['/login']);
              this.showError('Lo sentimos! Este usuario no cuenta con permisos para ingresar al aplicativo');
            }
          }
        } else {
          localStorage.setItem('authenticate', 'false');
          this._router.navigate(['/login']);
          this.showError(authResponse.message);
        }
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }

  showError(message) {
    this.toastr.error(message, '', { enableHtml: true });
  }
}
