import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SupplierRegistrationComponent} from './supplier-registration/supplier-registration.component';
import {TermsComponent} from './terms/terms.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {BrowserNotSupportedComponent} from './browser-not-supported/browser-not-supported.component';
import {OfflineExpectationComponent} from './offline-expectation/offline-expectation.component';
import {ModulesComponent} from './modules/modules.component';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {AuthGuard} from './gards/auth.gard';
import {InternalErrorComponent} from './modules/internal-error/internal-error.component';
import {PasswordComponent} from './password/password.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {SearchCompanyComponent} from './search-company/search-company.component';
import {SupplierRegistrationCompanyComponent} from './supplier-registration-company/supplier-registration-company.component';
import {FrequentQuestComponent} from './frequent-quest/frequent-quest.component';
import {ContactComponent} from './contact/contact.component';
import { TokenComponent } from './token/token.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'supplier-registration', component: SupplierRegistrationComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'browser-not-supported', component: BrowserNotSupportedComponent},
  {path: 'offline-expectation', component: OfflineExpectationComponent},
  {path: 'frequent-quest', component: FrequentQuestComponent},
  {path: 'password-recovery', component: PasswordComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'search-company', component: SearchCompanyComponent},
  {path: 'supplier-registration/company/:nit', component: SupplierRegistrationCompanyComponent},
  {path: 'token/:token', component: TokenComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {
    path: '',
    component: ModulesComponent,
    loadChildren: './modules/modules.module#ModulesModule',
    canActivate: [AuthGuard]
  },
  {path: '500', component: InternalErrorComponent},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404', pathMatch: 'full'},


];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
