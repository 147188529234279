import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BranchOfficeService {

  private subject = new Subject<any>();
  private branchOffice = null;

  setBranchOffice(bo: any) {
    this.branchOffice = bo;
    this.subject.next(this.branchOffice);
  }

  // getBranchOffice() {
  //    return this.branchOffice;
  // }

  getBranchOffice(): Observable<any> {
      return this.subject;
  }
}
