import { Action ,createAction, props } from '@ngrx/store';

export namespace CurrentPaymentsMethodTypes {
    export const ADD = '[CurrentPaymetsMethod] add';
    export const REMOVE = '[CurrentPaymetsMethod] remove';
}

export class AddCurrentPaymentsMethodAction implements Action {
    readonly type = CurrentPaymentsMethodTypes.ADD;
    public payload: any;

    constructor(public items: any){
        this.payload = items;
    }
}

export type CurrentPaymentMethodAction = AddCurrentPaymentsMethodAction;