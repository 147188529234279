import { Component, OnInit, VERSION } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {AffairsContactService} from '../services/affairs-contact/affairs-contact';
import {RelatedToService} from '../services/related-to/related-to.service';
import {ContactThroughService} from '../services/contact-through/contact-through.service';
import {CompanieInfoService} from '../services/companie-nfo/companie-info.service';

import {ContactService} from '../services/contact/contact.service';
import {AdverstismentService} from '../shared/services/advertisments/adverstisment.service';
import {ToastrService} from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';
import { HeaderService } from '../services/header/header.service';
import { UserInfo } from '../contact-us/contact-us.component';

declare var $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

// Componente de contacto (Usuario no loggeado).
export class ContactComponent implements OnInit {
  b2sContent: any;

  public affairs: any;
  public relatedto: any;
  public contactThroughs: any;
  public companieInfo: any;
  public contact: any;
  page = 'PAG-60';
  public cmsData: any;
  public registerForm: FormGroup;
  public isAuthenticate = false;
  public disabledButton = false;
  public userData: any;

  public contactUsModel = {
    name: '',
    surname: '',
    email: '',
    city: '',
    phone: '',
    indicative: ''
  };

  public contentB2s = {
    contact: 0,
    provider: 0
  };

  constructor(private affairService: AffairsContactService,
              private relatedtoService: RelatedToService,
              private contactThroughService: ContactThroughService,
              private companieInfoService: CompanieInfoService,
              private contactService: ContactService,
              public adService: AdverstismentService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private _authService: LoginService,
              private _hedaerService: HeaderService) {

    this.getContentB2s();
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip()
    this.adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
    this.loadAffairs(0);
    this.getContentB2s();
    this.loadCompanieInfo(0);
    this.loadRelatedTo(0);
    this.loadContactThrough(0);
  }

  // getContentB2s() {
  //   const provider = localStorage.getItem('provider');
  //   const contact = localStorage.getItem('contact');

  //   this.contentB2s.contact = parseFloat(contact);
  //   this.contentB2s.provider = parseFloat(provider);
  // }

  getContentB2s() {
    this._hedaerService.blogEventsHeader()
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });  
  }

  loadRelatedTo(n) {
    this.relatedtoService.getService().subscribe(
        result => {
          if (result.code !== 200) {
            this.relatedto = result;
          } else {
            n++;
            if (n < 4) {
              this.loadRelatedTo(n);
            }
          }
        },
        error => {
          // console.log(error as any);
        }
    );
  }

  loadContactThrough(n) {
    this.contactThroughService.getService().subscribe(
        result => {
          if (result.code !== 200) {
            this.contactThroughs = result;
          } else {
            n++;
            if (n < 4) {
              this.loadContactThrough(n);
            }
          }
        },
        error => {
          console.log(error as any);
        }
    );
  }

  loadAffairs(n) {
    this.affairService.getService().subscribe(
        result => {
          if (result.code !== 200) {
            this.affairs = result.Matters;
          } else {
            n++;
            if (n < 4) {
              this.loadAffairs(n);
            }
          }
        },
        error => {
          console.log(error as any);
        }
    );
  }

  loadCompanieInfo(n) {
    this.companieInfoService.getService().subscribe(
        result => {
          if (result.code !== 200) {
            this.companieInfo = result;

          } else {
            n++;
            if (n < 4) {
              this.loadCompanieInfo(n);
            }
          }
        },
        error => {
          console.log(error as any);
        }
    );
  }

  onSubmit(form) {
    this.disabledButton = true;
    this.registerForm = this.formBuilder.group({
      email: [form.value.email, [Validators.email]],
      phone: [form.value.phone, [Validators.maxLength(10), Validators.minLength(10)]],
    });

    for (const campo in form.controls) {
      if (form.controls[campo].errors != null) {
        if (form.controls[campo].errors.required) {
          this.showError(campo, 'Es obligatorio');
        }
      }
    }
    for (let propiedad in this.registerForm.controls) {
      if (this.registerForm.controls[propiedad].errors != null) {
        if (this.registerForm.controls[propiedad].errors.email != null) {
          if (this.registerForm.controls[propiedad].errors.email) {
            this.showError('Correo', 'Formato invalido');
            this.disabledButton = false;
          }
        }
        if (this.registerForm.controls[propiedad].errors.maxlength != null) {
          if (this.registerForm.controls[propiedad].errors.maxlength) {
            this.showError('Teléfono', 'El Teléfono Móvil debe tener 10 digitos');
            this.disabledButton = false;
          }
        }
        if (this.registerForm.controls[propiedad].errors.minlength != null) {
          if (this.registerForm.controls[propiedad].errors.minlength) {
            this.showError('Teléfono', 'El Teléfono Móvil debe tener 10 digitos');
            this.disabledButton = false;
          }
        }
      }
    }
    if (form.valid && this.registerForm.valid) {
      this.disabledButton = true;
      this.sendRequest(form);
    }
  }

  getUserInfo(userId) {
    this._authService.getUserInfo(userId)
        .subscribe((userData: UserInfo) => {
          this.contactUsModel.name = userData.userInfo.firstName;
          this.contactUsModel.surname = userData.userInfo.lastName;
          this.contactUsModel.email = userData.userInfo.email;
          this.contactUsModel.city = userData.userInfo.cityName;
          this.contactUsModel.phone = userData.userInfo.movil;
          this.contactUsModel.indicative = userData.userInfo.movilIndicative;
        });
  }

  sendRequest(form) {
    this.contactService.getService(form).subscribe(
        result => {

          if (!result.error) {
            this.contact = result;
            this.disabledButton = false;
            this.showSucess('Mensaje', 'El correo se ha enviado correctamente');
            form.reset();
            this.resetSelects();
            // if (localStorage.getItem('userData') !== null) {
            //   this.getUserInfo(this.userData.userId);
            // }
          } else {
            this.contact = result.data;
          }
        },
        error => {

        }
    );
  }

  showError(title: string, message: string) {
    if (message.length > 0) {
      this.toastr.error(message,
          title, {
            enableHtml: true,
            closeButton: true,
          });
    }
  }

  showSucess(title: string, message: string) {
    if (message.length > 0) {
      this.toastr.success(message,
          title, {
            enableHtml: true,
            closeButton: true,
          });
    }
  }

  initModelContacUs() {
    this.contactUsModel.name = this.userData.contactFirstName.trim();
    this.contactUsModel.surname = this.userData.contactLastName.trim();
    this.contactUsModel.email = this.userData.contactEmail.trim();
  }

  resetSelects() {
    let allSelects = document.getElementsByTagName('select');
    for (let i = 0; i < allSelects.length; i++) {
      allSelects[i].value = '';
    }
  }


}
