import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { BranchOfficeService } from '../../services/branch-office/branch-office.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { PaymentHistoryService } from '../../../services/payment-history/payment-history.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  blogEvent: any;
  logo: string;
  jsonUser: any;
  nameUser: string;
  showForm: false;
  diaErp: any;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;
  public branchOffice: string = null;
  subscription: Subscription;

  // tslint:disable-next-line:variable-name
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private paymentHistoryService: PaymentHistoryService,
    private _branchOfficeService: BranchOfficeService
    ) { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.logo = localStorage.getItem('logo');
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
    this.nameUser = this.jsonUser.contactFirstName;
    this.getRetornarDias();

    if (localStorage.getItem('selectedSucursal') === null) {
      this.branchOffice = 'No Seleccionada';
    } else {
      if (JSON.parse(localStorage.getItem('selectedSucursal')) === null) {
      this.branchOffice = 'Todas las sucursales';
      }
      this.branchOffice = JSON.parse(localStorage.getItem('selectedSucursal')).name;
    }
    this.subscription = this._branchOfficeService.getBranchOffice().subscribe(bo => {
      this.branchOffice = bo === null ? 'Todas las sucursales' : bo.name;
    });
  }

  logout() {
    this._authService.logoutService();
    localStorage.removeItem('authenticate');
    localStorage.removeItem('userData');
    localStorage.removeItem('selectedSucursal');
    localStorage.removeItem('valor_minimo_anticipo_agp');
    localStorage.removeItem('valor_minimo_abono_agp');
    // localStorage.removeItem('dateUntilSearch');
    // localStorage.removeItem('dateFromSearch');
    localStorage.clear();

    this._router.navigate(['/login']);
  }

  getRetornarDias() {

      this.paymentHistoryService.getRetornarDias().then(respuesta => {

        console.log(respuesta[`data`]);
        if (respuesta[`data`]) {
            this.diaErp = respuesta[`data`];
        } else {
          this.diaErp = 30;
        }


        localStorage.setItem('thisDiaErp', JSON.stringify(this.diaErp));
      });

  }

}
