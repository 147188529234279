import { Component, Renderer2, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from './services/login/login.service';
import { OfflineService } from '../app/shared/services/page-offline/offline.service';
import { DOCUMENT } from '@angular/common';
import { EnvService } from './services/utils/env.service';
import { ManageLocalStorageService } from './services/manage-localstorage-service/manage-localstorage-service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    title = 'zona de pagos';
    flagoffline: boolean;
    contentB2s: any;


  constructor(private renderer: Renderer2,
              private _loginService: LoginService,
              private _router: Router,
              private env: EnvService,
              private _offlineService: OfflineService,
              @Inject(DOCUMENT) private _document: HTMLDocument,
              private _manageLocalStorage : ManageLocalStorageService) {

    this.statusServer();
    this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            if ((event.url === '/login') || (event.url === '/register-login') || (event.url === '/password') ||
                (event.url === '/password-recovery') || (event.url.length == 1)) {
                this.renderer.addClass(document.body, 'bg-gradient-primary');
                this.renderer.addClass(document.body, 'body-login');
            } else {
                this.renderer.removeClass(document.body, 'bg-gradient-primary');
                this.renderer.removeClass(document.body, 'body-login');
            }

            if (event.url.indexOf('backend') > -1 ) {
                window.location.href = `${this.env.apiGatewayBackOffice}`;
            }

          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');

          this._manageLocalStorage.limitNavigation(event.url);
        }
      });


    this._offlineService.getStatusPage().subscribe( statusPage =>{
      this.flagoffline = statusPage.pageOnline;
      if (!this.flagoffline) {
        this._router.navigate(['/offline-expectation']);
      }
    });
    this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
    this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
  }

  statusServer() {
    this._loginService.statusServer()
      .subscribe((authResponse: any) => {

        console.log(authResponse);
        this.contentB2s = authResponse;
        localStorage.setItem('logo', this.contentB2s.logo);
        localStorage.setItem('favicon', this.contentB2s.img_favicon);
        localStorage.setItem('page_title', this.contentB2s.page_title);
        localStorage.setItem('provider', this.contentB2s.provider);
        localStorage.setItem('contact', this.contentB2s.contact);
        localStorage.setItem('date_payment', this.contentB2s.date_payment);
        localStorage.setItem('status_payment', this.contentB2s.status_payment);
        localStorage.setItem('date_invoice', this.contentB2s.date_invoice);

        /**
         * @Eder
         */
        localStorage.setItem('old_payments_firts', this.contentB2s.old_payments_firts);
        localStorage.setItem('pago_cualquier_factura', this.contentB2s.pago_cualquier_factura);
        localStorage.setItem('permite_pagar_abonos', this.contentB2s.permite_pagar_abonos_facturas);
        localStorage.setItem('bloqueo_factura_en_estado_de_pago', this.contentB2s.bloqueo_factura_en_estado_de_pago);
        localStorage.setItem('ingresar_descripcion_recibo_caja', this.contentB2s.ingresar_descripcion_recibo_caja);
        localStorage.setItem('valor_pagar_cero', this.contentB2s.valor_pagar_cero);
        localStorage.setItem('ingresar_valor_minimo_documentos', this.contentB2s.ingresar_valor_minimo_documentos);

        /**
         * @Fin
         */
        this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
        this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }
}

