import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../utils/env.service';
import {constants} from '../../../config/app.constants';


@Injectable({
  providedIn: 'root'
})

export class TokenService {
    private url: string;
    private contentB2s: any = {};
    private contentCms: any;
    private ubicacion = 'SOLICITUD_REGISTRO_PROVEEDORES';
    private ubicacionError = 'TERCERO_NO_EXISTE';

  constructor( private http: HttpClient, private env: EnvService) {
    this.url = this.env.apiGatewayFront;
  }

  getTokenService(token) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('token', token);

    return this.http.post(`${this.url}/${constants.config.verifyToken}`, body, { headers });
  }
}
