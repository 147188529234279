import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';
import { of , concat, from } from 'rxjs';
import { map } from 'rxjs/operators';
// tslint:disable-next-line:quotemark
import swal from "sweetalert2";
import { PrintService } from '../shared/services/print/print.service';
import {AdverstismentService} from '../shared/services/advertisments/adverstisment.service';
import { PaymentHistoryService } from '../services/payment-history/payment-history.service';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('sealRef') sealRef;


  registerForm: FormGroup;
  contentCms: any;
  logo: string;
  public data: any;
  public dataForm = {
    inputEmail: '',
    inputPassword: ''
  };
  public contentB2s = {
    contact: 0,
    provider: 0
  } ;

  page = 'PAG-66';
  cmsData: any;


  // tslint:disable-next-line:variable-name
  constructor(private _authService: AuthService,
              // tslint:disable-next-line:variable-name
              private _router: Router,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              // tslint:disable-next-line:variable-name
              private _loginService: LoginService,
              private printService: PrintService,
              public adService: AdverstismentService,
              private paymentHistoryService: PaymentHistoryService,) {

    if (this._authService.isAutenticate()) {
      this._router.navigate(['/payments']);
    }
    this.getCmsContent();

    }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.logo = localStorage.getItem('logo');
    const interval = setInterval(() => {
      this.getContentB2s();
      clearInterval(interval);
    }, 500);


  }

  authentication(form: NgForm) {
    let userName = form.value.inputEmail;
    let password = form.value.inputPassword;
    let message = '';

    this.registerForm = this.formBuilder.group({
      email: [userName, [Validators.required, Validators.pattern('^([a-zA-Z0-9_.+-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$')]],
      password: [password, [Validators.required]]
    });

    if (this.registerForm.controls.email.errors) {
      if (this.registerForm.controls.email.errors.required) {
        message = message + '<br>Ingrese el correo electrónico';
      }
      if (this.registerForm.controls.email.errors.pattern) {
        message = message + '<br>Ingrese un correo válido.';
      }
    }
    if (this.registerForm.controls.password.errors) {
      if (this.registerForm.controls.password.errors.required) {
        message = message + '<br>Ingrese la contraseña.';
      }
    }

    if (this.registerForm.invalid) {
      this.toastr.error(message, 'Faltan algunos datos por diligenciar', {enableHtml: true});
      /*swal.fire({
        title: '<p>faltan algunos datos por diligenciar</p>',
        html: message,
        type: 'error',
      });*/
      return;
    }

    this._authService.authenticate(userName, password)
      .subscribe((authResponse: any) => {
        if (authResponse.data) {
          if (authResponse.data.isLogged) {
            if (authResponse.data.isAGP) {
              localStorage.setItem('authenticate', 'true');
              localStorage.setItem('userData', JSON.stringify(authResponse.data));
              this.getAllCmsToAgp();
              this.paymentHistoryService.getRetornarDias().then(respuesta => {
                let diaErp = 0;
                if (respuesta[`data`]) {
                    diaErp = respuesta[`data`];
                } else {
                  diaErp = 30;
                }
                localStorage.setItem('thisDiaErp', JSON.stringify(diaErp));
                this._router.navigate(['/payments']);
              });
            } else {
              localStorage.setItem('authenticate', 'false');
              this._router.navigate(['/login']);
              this.showError('Lo sentimos! Este usuario no cuenta con permisos para ingresar al aplicativo');
            }
          }
        } else {
          localStorage.setItem('authenticate', 'false');
          this.showError(authResponse.message);
        }
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }

    getCmsContent() {
        const ubucation = 'INICIO_AGR';
        this._loginService.contentCms(ubucation)
            .subscribe((loginResponse: any) => {
                return this.contentCms = loginResponse;
            });
    }

  getContentB2s() {
    const provider = localStorage.getItem('provider');
    const contact = localStorage.getItem('contact');

    this.contentB2s.contact = parseFloat(contact);
    this.contentB2s.provider = parseFloat(provider);
  }

  showError(message) {
    this.toastr.error(message, '', { enableHtml: true });
  }


  getAllCmsToAgp() {
    this.printService.getAllCms().subscribe(
      resultado => {
        this.searchInCmsAndSetLocalStorage(resultado);
       // console.log(resultado);
      },
      error => {
        console.log('an InvoiceCmsMensaje error', error);
      }
    );
  }

  private searchInCmsAndSetLocalStorage(resultado: any) {
    const objectsToFind = [
      'MENSAJE_DE_FACTURAS_NO_DISPONIBLES_AGP',
      'MENSAJE_PAGO_FACTURAS_ANTERIORES_AGP',
      'old_payments_firts'
    ];

    const obsArray = from(objectsToFind);
    const subscribe = obsArray
      .pipe(
        map((x: any) => {
          const foundItem = resultado.find((value: any) => {
            return value.ubicacion === x;
          });
          return foundItem;
          //console.log(foundItem);
        })
      ).subscribe((response: any) => {
        if (response !== undefined) {
          localStorage.setItem(response.ubicacion, response.contenido);
        }
      });

  }

  ngAfterViewInit() {


    this.adService.getAdverstisments(this.page).subscribe((data: any) => {

      var iframe = document.createElement('iframe');
      this.cmsData = data.espacios['1'].html;
      console.log("this.cmsData", data.espacios['1'].html);

        //APPLY STYLES TO IFRAME AS SUITABLE
      iframe.width = '250';
      iframe.height = '100';
      iframe.frameBorder = '0';
      iframe.scrolling = "no";
      iframe.src = 'about:blank';
      //ADD OTHER PROPERTIES OF IFRAME AS SUITABLE

      /*var content =`
      <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=k3ZLpYkTifADjq9xlAlkiWKxUcDxE1k3Nx07uoNgARam3m7fFkJuret1GDhn"></script></span>      `*/
      console.log("iframe",this.cmsData)
      var content =`${this.cmsData}`
      this.sealRef.nativeElement.appendChild(iframe);

      iframe.contentWindow.document.open('text/html', 'replace');
      iframe.contentWindow.document.write(content);
      iframe.contentWindow.document.close();

    });


}


}

